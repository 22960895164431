.dialogContent {
  display: grid;
  gap: calc(var(--mui-spacing) * 2);
  grid-template-rows: auto 1fr;
  min-height: 50vh;
}

.filters {
  display: grid;
  gap: calc(var(--mui-spacing) * 2);
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.hwidFilter {
  align-items: center;
  display: flex;
  gap: var(--mui-spacing);
}

.root {
  display: block;
  text-align: center;
}

.table {
  min-height: 200;
}
