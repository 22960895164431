.root {
  color: var(--mui-palette-text-secondary);
  display: flex;
  gap: 0.5rem;
  margin-left: 1rem;
}

:global(.dark) .root {
  color: var(--mui-palette-text-primary);
}
