.root {
  display: block;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
