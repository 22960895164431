.appbar {
  grid-area: app-bar;
}

.grid {
  display: grid;
  grid:
    "app-bar" auto
    "main" 1fr
    / 1fr;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.grow {
  flex-grow: 1;
}

.logo {
  margin-right: calc(var(--mui-spacing) * 2);
  transform: scale(0.95) translateY(-2px);
}

.main {
  grid-area: main;
  overflow: auto;
  position: relative;
  will-change: transform;
}
