.fullWidth {
  display: flex;
}

.fullWidth .label {
  flex: 1;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
}

.root {
  align-items: flex-end;
  display: inline-flex;
}
