.root {
  font-size: 1.5em;
}

.image {
  display: block;
  height: 1em;
  object-fit: contain;
  pointer-events: none;
  width: 1em;
}
