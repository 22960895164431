.root {
  min-width: 0;
  text-overflow: ellipsis;

  background: none;
  border: none;

  color: inherit;
  font-family: inherit;
  font-size: inherit;
}
